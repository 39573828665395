<template>
  <div>
    <main class="content-500">
      <div class="t-center pad-16">
        <h3 style="font-size: 2em;" class="f-bold color-white">PROMOTER</h3>
        <h4 style="font-size: 1.3em;" class="color-white primaryColor">
          👉 BOCK AUF FREIEN EINTRITT, FREIGETRÄNKE & VIP-BEREICH?
          <br>DANN KOMM IN UNSER TEAM 🎉
        </h4>
        <p class="t-left" style="margin-top: 8px;">
          Als offizieller Promoter von Limburg Empire, feierst du wie ein König/wie eine Königin!<br><br>
          <b class="f-bold">ℹ️ DEINE AUFGABEN:</b><br>
          Du supportest deine ausgewählten Events in deiner Stadt und erhälst dafür alle Vorteile.
          Dein Ticket schicken wir dir dann per E-Mail zu und du kannst an der Schlange vorbei gehen, zahlst keinen Eintritt und hast All You Can Drink* im VIP-Bereich.
        </p>
        <p class="t-left" style="margin-top: 8px;">
          <b class="f-bold">⚠️ Kein Risiko, kein Vertrag, keinen Haken.</b>
          Hast du Bock? 😏<br>
          Dann füll das Kontaktformular aus und wir melden uns direkt per Whatsapp bei dir.
        </p>
      </div>
      <form @submit.prevent="submitPromoteRequest()" class="pad-8">
        <div class="field simple pad-8">
          <label for="phone">Deine Handynummer:</label>
          <input placeholder="Telefonnummer" type="tel" id="phone" name="phone" v-model="phone" @change="validateHandy">
          <p class="color-red" v-if="phoneError">{{phoneError}}</p>
        </div>
        <div class="field simple pad-8">
          <label for="plz">Deine Postleitzahl: </label>
          <input placeholder="PLZ" type="number" id="plz" name="plz" v-model="plz" maxlength="5" @change="validatePlz">
          <p class="color-red" v-if="plzError">{{plzError}}</p>
        </div>
        <div class="field simple pad-8">
          <label for="city">Deine Stadt: </label>
          <input placeholder="Stadt" id="city" name="city" v-model="city" @change="validateCity">
          <p class="color-red" v-if="cityError">{{cityError}}</p>
        </div>
        <div class="field simple pad-8">
          <label for="clubs">In welchen Clubs gehst du immer feiern?</label>
          <textarea placeholder="In welchen Clubs gehst du immer feiern?" id="clubs" name="clubs" v-model="clubs"></textarea>
          <p class="color-red" v-if="cityError">{{cityError}}</p>
        </div>
        <div class="pad-8" v-if="!successMessage">
          <button class="button bgcolor-white bgprimaryBtnColor color-black fluid">
            Absenden 🚀
          </button>
          <p style="font-size: 0.8em; margin-top: 8px;">
            Mit dem Absenden bestätigst du, dass du bezüglich dieser Anfrage kontaktiert werden darfst. Es gelten unsere Datenschutzbestimmungen.
          </p>
        </div>
      </form>
      <div v-if="successMessage" class="t-center">
        <span style="display: inline-block;" class="green label">Deine Nachricht wurde erfolgreich versicht.<br>Wir werden uns in kürze bei dir melden.</span>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "promoterRequest",
  data(){
    return{
      phone:'',
      phoneError:'',
      plz:'',
      plzError:'',
      city:'',
      cityError:'',
      clubs: '',
      successMessage: false,
      errorMessage:''
    }
  },
  methods:{
    validateHandy(){
      if (!/^[0-9]+$/.test(this.phone)){return this.phoneError = 'Bitte gebe bei der Handynummer nur Zahlen an'}
      if (this.phone.length < 10){return this.phoneError = 'Handynummer zu kurz'}
      if (this.phone.length > 15){return this.phoneError = 'Handynummer zu lang'}
      this.phoneError = ''
    },
    validatePlz(){
      if (this.plz.length < 4){return this.plzError = 'Postleitzahl zu kurz'}
      if (this.plz.length > 5){return this.plzError = 'Postleitzahl zu lang'}
      if (!/^[0-9]+$/.test(this.plz)){return this.plzError = 'Bitte gebe bei der Postleitzahl nur Zahlen an'}
      this.plzError = ''
    },
    validateCity(){
      if (this.plz.length < 2){return this.cityError = 'Stadtname zu kurz'}
      if (this.plz.length > 40){return this.cityError = 'Stadtname zu lang'}
      if (this.city.match(/[0-9]/)){return this.cityError = 'Entferne die Zahlen aus dem Stadtnamen'}
      this.cityError = ''
    },

    submitPromoteRequest(){
      this.validateHandy()
      this.validatePlz()
      this.validateCity()
      if (this.phoneError || this.plzError || this.cityError){return}
      const _this = this
      const fD = new FormData()
      fD.append('phone', this.phone)
      fD.append('plz', this.plz)
      fD.append('city', this.city)
      fD.append('clubs', this.clubs)
      this.axios.post('promoter/sendMessage', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.successMessage = true
            }
          })
    }
  }
}
</script>

<style scoped>
  label{
    display: block;
    font-weight: normal;
    margin-bottom: 4px;
  }
  .field input,
  .field textarea{
    padding: 12px;
    border: none;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.4);
    box-sizing: border-box;
    color: #ffffff;
  }
  .green.label{
    color: #08cc77;
    background-color: #32ee9b33;
  }
</style>