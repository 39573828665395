<template>
  <main class="content-500">
    <section id="walletHeader">
      <div class="pad-16">
        <div class="grid-12">
          <div class="col-sp-8 row-sp-12">
            <h1>Digitale Garderobe</h1>
            <p>Bezahle direkt mit der App und erhalte eine Digitale Garderobenmarke.</p>
          </div>
          <div class="col-sp-4 row-sp-12 t-right">
            <router-link to="/wallet">
              <button class="button bgcolor-white bgprimaryBtnColor color-black">
                {{ (user.credits / 100).toFixed(2) }}€
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section id="walletCTA" v-if="this.$route.params.id && eventDetails">
      <div class="pad-8">
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12 pad-8" v-if="!openGaderobeItems.length">
            <button @click="checkOutModal = true;" class="button bgcolor-white bgprimaryBtnColor color-black" id="pay">
              <svg width="80px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h256v256H0z"></path><path d="M241.6 171.2 141.3 96l23.5-17.6A8.1 8.1 0 0 0 168 72a40 40 0 0 0-80 0 8 8 0 0 0 16 0 24 24 0 0 1 47.7-3.8l-28.4 21.3-.2.2-108.7 81.5A16 16 0 0 0 24 200h208a16 16 0 0 0 9.6-28.8ZM24 184l104-78 104 78H24Z" fill="#ffffff" class="fill-000000"></path></svg>
              <h4>Garderoben Marke für {{eventDetails.gaderobePrice.toFixed(2)}}€ Kaufen</h4>
            </button>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8 t-center" v-else>
            <h3 class="f-bold pad-8" style="font-size: 1.2em;">Bitte zeige diesen QR Code an der Garderobe vor, um deine Sachen abzugeben.</h3>
            <img id="qrious">
            <p v-if="qrloading">QR Code wird generiert, bitte warten.</p>
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="pad-8">
        <div class="bgcolor-white bgprimaryBtnColor pad-24 t-center color-black br-10">
          <h3 class="f-bold">Bitte wähle ein Event aus</h3>
          <div class="field">
            <select v-model="eventId" @change="this.$router.push('/garderobe/'+eventId)" class="pad-8 br-10">
              <option value="-1" disabled>
                Event...
              </option>
              <option v-for="event in events" :key="event.id" :value="event.id">
                {{ event.title + ' - ' + event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </section>
    <section id="paymentHistory">
      <div class="pad-16">
        <h2 class="t-center">Deine aktuellen Garderobenmarken</h2>
        <div class="pad-32 t-center" v-if="!gaderobeItems.length">
          <p>Keine Garderobenmarken vorhanden</p>
        </div>
        <div v-else>
          <div class="col-sp-12 row-sp-12 pad-8 t-center" v-if="pickup">
            <h3 class="f-bold pad-8" style="font-size: 1.2em;">Bitte zeige diesen QR Code an der Garderobe vor, um deine Sachen abzuholen.</h3>
            <img id="qrious2">
          </div>
          <div v-for="gaderobe in gaderobeItems" :key="gaderobe.id">
            <div class="paymentItem">
              <div class="paymentItemAmount">
                <div class="grid-12">
                  <div class="col-sp-6 row-sp-12 t-left">
                    <h3 style="font-size: 1.6em;">#{{gaderobe.pickupNumber}}</h3>
                  </div>
                  <div class="col-sp-6 row-sp-12 t-right">
                    <button class="tiny button bgcolor-white bgprimaryBtnColor color-black" @click="generatePickupQRCode(gaderobe.secureCode); pickupId = gaderobe.id;">Abholcode anzeigen</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <VueModal :show="checkOutModal" @close="checkOutModal = false" min-size="small" max-size="large">
      <check-out :item="eventDetails" checkout-type="garderobe" @close="checkOutModal = false" />
    </VueModal>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import Qrious from "qrious";
import {aSyncData} from "@/modules/main";
import CheckOut from "@/components/payment/checkOut";


export default {
  name: "Garderobe",
  title: "Garderobe",
  components: {CheckOut},
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      events: [],
      gaderobeItems: [],
      openGaderobeItems: [],
      eventDetails: '',
      eventId: this.$route.params.id,
      checkInterval: '',
      pickup: false,
      pickupId: '',
      checkPickupInterval: '',
      checkOutModal: false,
      qrloading: false,

    }
  },
  watch: {
    paymentModal(){
      this.init()
    }
  },
  mounted() {
    this.init()
    this.getEvents()
    this.getEventData()
    this.config.loading = false
  },
  methods: {
    init(){
      aSyncData('garderobe/getData')
          .then(r => {
            if(r.status === 250) {
              this.gaderobeItems = r.data.gaderobeItems
              this.openGaderobeItems = r.data.openGaderobeItems
              if(this.openGaderobeItems.length >= 1){
                this.qrloading = true
                this.generateQRCode(r.data.openGaderobeItems[0].secureCode)
                this.checkStatus()
              }
            }
          })
          .catch(e => {console.log(e)})


    },
    getEventData(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId', this.$route.params.id)
      this.axios.post('garderobe/getEventDetails', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              this.eventDetails = response.data;
            }
          })
    },
    checkStatus(){
      this.checkInterval = setInterval(function(){
        const fD = new FormData()
        fD.append('sessionID', this.auth.sessionID)
        this.axios.post('garderobe/getData', fD)
            .then((response) => {
              if(response.status === 250){
                if(response.data.openGaderobeItems.length == 0){
                  clearInterval(this.checkInterval)
                  this.openGaderobeItems = [];
                  location.reload();
                }
              }else{
                clearInterval(this.checkInterval)
              }
            })
      }.bind(this), 3000);
    },
    checkPickupStatus() {
      this.checkPickupInterval = setInterval(function () {
        const fD = new FormData()
        fD.append('id', this.pickupId)
        fD.append('sessionID', this.auth.sessionID)
        this.axios.post('garderobe/getById', fD)
            .then((response) => {
              if (response.status === 250) {
                if (response.data.status == 2) {
                  this.pickup = false;
                  clearInterval(this.checkPickupInterval)
                  this.openGaderobeItems = [];
                  location.reload();
                }
              } else {
                clearInterval(this.checkPickupInterval)
              }
            })
      }.bind(this), 3000);
    },
    generateQRCode(secureCode, maxRetries = 3, retryDelay = 500) {
      const tryGenerateQRCode = (retryCount) => {
        const element = document.getElementById('qrious');
        if (element) {
          new Qrious({
            element,
            size: 350,
            value: secureCode,
            background: 'transparent',
            foreground: '#ffffff',
            level: 'H',
          });
          this.qrloading = false
          return true;
        } else if (retryCount < maxRetries) {
          setTimeout(() => {
            tryGenerateQRCode(retryCount + 1);
          }, retryDelay);
        } else {
          console.error('Das Element mit der ID "qrious" konnte nicht gefunden werden.');
          return false;
        }
      };
      return tryGenerateQRCode(0);
    },
    generatePickupQRCode(secureCode) {
      this.pickup = true;
      this.$nextTick(() => {
        new Qrious({
          element: document.getElementById('qrious2'),
          size: 350,
          value: secureCode,
          background: 'transparent',
          foreground: '#ffffff',
          level: 'H',
        });
      })
      this.checkPickupStatus()
    },
    getEvents(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('garderobe/getEvents', fD)
          .then(r => {
            console.log(r.data, r.status)
            if(r.status === 250) {
              _this.events = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
  }
}
</script>

<style scoped>
#walletHeader{
  padding-top: 16px;
}
#walletHeader h1{
  font-size: 2em;
  font-weight: 300;
}
#walletHeader button{
  font-size: 1em;
  padding: 8px;
}
#walletCTA button{
  width: 100%;
}
.paymentModal{
  position: fixed;
}
.paymentModal .vueModalInner{
  min-width: 80vw;
}
.paymentModal h2{
  font-size: 2em;
}
.paymentModal h3{
  font-size: 1.2em;
  margin-bottom: 16px;
}
#chargeModal{
  position: fixed;
}
#chargeModal .vueModalInner{
  min-width: 80vw;
}
#chargeModal h2{
  font-size: 1.8em;
  font-weight: 300;
}
#chargeModal h3{
  font-size: 1.2em;
  margin-bottom: 16px;
}
#chargeModal .selectionButtons{
  max-width: 250px;
  margin: 0 auto;
}
#chargeModal .selectionButtons button{
  padding: 8px;
  font-size: 1.0em;
  margin-bottom: 32px;
}
#chargeModal #chargeInput{
  padding: 16px;
  width: calc(100% - 20px);
  max-width: 200px;
  background: #000000;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 1.2em;
}
#chargeModal .field span{
  font-size: 1.5em;
  margin-left: 8px;
}
#paymentHistory h2{
  font-size: 1.2em;
}
.paymentItem{
  margin-top: 8px;
  padding: 12px;
  border-radius: 10px;
  background-color: var(--second-bg-color);
}
.paymentItem .paymentItemAmount .t-right{
  font-size: 1.2em;
}
.paymentItem .paymentItemAmount .t-right.negative{
  color: darkred;
}
.paymentItem .paymentItemAmount .t-right.positive{
  color: green;
}
.paymentItem .paymentItemDate{
  color: rgba(255,255,255,0.6);
  font-size: 0.8em;
}

.qrcode img{
  width: 100%;
  height: auto;
  max-width: 300px;
}

.flex{
  display: flex;
}

.buttons-wrapper{
  flex-wrap: wrap;
  column-gap: 100%;
  row-gap: 8px;
}

.button-primary{
  width: 100%;
}

</style>