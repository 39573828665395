<template>
  <div class="checkoutType">
    <div class="header">
      <transition name="login" mode="out-in">
        <div v-if="auth.isAuth" class="loggedInAs">
          Eingeloggt als:
          {{ user.firstname }} {{ user.lastname }}
        </div>
        <div v-else>
          <div class="flex checkoutAs-Wrapper">
            <div @click="orderAs = 'guest'" class="button color-white">
              Als Gast bestellen
            </div>
            <div @click="orderAs = 'user'" class="button-primary bgprimaryBtnColor">
              Einloggen
            </div>
          </div>
          <transition name="login" mode="out-in">
            <div v-if="orderAs === 'guest'" class="checkoutAsGuest-wrapper flex" style="justify-content: center;">
              <input type="text" v-model="guestData.firstname" placeholder="Vorname">
              <input type="text" v-model="guestData.lastname" placeholder="Nachname">
              <input type="email" v-model="guestData.email" placeholder="E-Mail" @focusout="validateEmail()">
              <input type="tel" v-model="guestData.phone" placeholder="Telefon">
              <p class="t-center color-red">
                {{ errormessage }}
              </p>
            </div>
            <div v-else>
              <div v-if="!loginIn" class="checkoutLogin-wrapper flex" style="justify-content: center;">
                <input type="email" v-model="loginData.email" placeholder="E-Mail">
                <input type="password" v-model="loginData.password" placeholder="Passwort" @keydown.enter="logIn">
                <button type="button" @click="logIn" class="button buttonLogin bgprimaryBtnColor">
                  Einloggen
                </button>
              </div>
              <loader size="small" v-else />
            </div>
          </transition>
        </div>
      </transition>
    </div>
    <component @close="closeParentModal()" :is="checkoutType" :item="item" :ready-state="readyState" :user-info="auth.isAuth ? user : guestData" />
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";
import Loader from "@/components/basic/loader";
import tickets from "@/components/payment/tickets";
import credits from "@/components/payment/credits";
import lounges from "@/components/payment/lounges";
import upgrade from "@/components/payment/upgrade";
import garderobe from "@/components/payment/garderobe";

export default {
  name: "checkOut",
  components: {
    Loader,
    tickets,
    lounges,
    credits,
    upgrade,
    garderobe
  },
  props: {
    checkoutType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    }
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      errormessage: '',
      guestData: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
      },
      loginData: {},
      loginIn: false,
      orderAs: 'guest'
    }
  },
  computed: {
    readyState(){
      return this.auth.isAuth || (this.guestData.firstname.length && this.guestData.lastname.length && this.guestData.email.length && this.guestData.phone.length)
    }
  },
  methods: {
    closeParentModal(){
      this.$emit('close')
    },
    logIn(){
      this.loginIn = true
      this.auth.login(this.loginData.email, this.loginData.password, false)
          .catch(e => {})
          .finally(() => {
            this.loginIn = false
          })
    },
    validateEmail(){
      if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(this.guestData.email)){
        this.errormessage = '';
      }else{
        this.errormessage = 'Bitte gebe eine gültige E-Mail-Adresse an.';
      }
    }
  }
}
</script>

<style scoped>
.checkoutType{
  overflow-y: scroll;
  max-height: 85vh;
}

.login-enter-active,
.login-leave-active {
  transition: all 0.2s ease;
}

.login-enter-from{
  opacity: 0;
  transform: translateX(200px);
}
.login-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}

.checkoutAs-Wrapper{
  flex-wrap: wrap;
  column-gap: 100%;
  justify-content: center;
  row-gap: 10px;
}

.checkoutAs-Wrapper .button-primary, .checkoutAs-Wrapper .button{
  width: 60%;
}

.checkoutAsGuest-wrapper, .checkoutLogin-wrapper{
  flex-wrap: wrap;
  column-gap: 100%;
  row-gap: 10px;
  margin: 24px 0;
}

.checkoutAsGuest-wrapper input, .checkoutLogin-wrapper input{
  padding: 8px;
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background-color: #1E1E1E;
  color: #ca0001;
}

.buttonLogin{
  width: 100%;
}

.loggedInAs{
  opacity: 0.5;
  font-size: 0.9em;
}

.header{
  margin-top: 20px;
}
</style>