<template>
  <div class="text-white px-4 mt-24 md:max-w-screen-xl md:m-auto md:mt-48" style="max-width: 1536px;color:white;padding: 0 16px;margin: 250px auto">
    <h2 class="text-4xl text-center font-semibold">Allgemeine Geschäfts Bedingungen</h2>
    <ol class="space-y-6">
      <li>
        <h3 class="text-2xl pt-4 relative group">
          1.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Unsere Veranstaltungen sind ab 18 Jahren. Es gibt Ausnahme-Veranstaltungen, welche ab 16 Jahren mit einem ausgefüllten & vollständigen “Muttizettel” von www.clubzettel.de und in Begleitung einer volljährigen Aufsichtsperson für Gäste zwischen 16-17 Jahren besuchbar sind. Diese Veranstaltungen werden mit dem Vermerk “16+” gekennzeichnet.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          2.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Um an unserer Veranstaltung teilnehmen zu können, muss der Gast sich mit einem Personalausweis oder Reisepass ausweisen. Andere Ausweisarten können ggf. abgelehnt werden.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          3.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Wir halten uns an die Corona-Sicherheitsmaßnahmen & Vorgaben der Regierung. Es gelten die Einlass-Bestimmungen, welche zum Zeitpunkt der Veranstaltung vorgegeben werden. Jeder Gast ist selbst dafür verantwortlich, diese Voraussetzung zu erfüllen.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          4.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Es wird während der gesamten Veranstaltung Foto- und Filmaufnahmen von der Veranstaltung sowie dem Außengelände gemacht. Dem Gast ist hiermit bekannt, dass die Yavuz GmbH im Rahmen der Veranstaltung Foto- bzw. Filmmaterial anfertigt bzw. von Dritten anfertigen lässt. Der Besucher willigt beim Betreten der Veranstaltung in die Herstellung, Vervielfältigung und Veröffentlichung des im Rahmen der Veranstaltung entstehenden Bildmaterials in jeder Art Medium durch die Yavuz GmbH oder durch Dritte, soweit die Veröffentlichung im Zusammenhang mit der Yavuz GmbH steht, ein.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          5.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Jedes Ticket darf weiterverkauft werden. Eine Rückgabe von Veranstaltungstickets ist in Deutschland vom Widerrufsrecht gesetzlich ausgeschlossen.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          6.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Alle Tickets sind nur für den Ticketbesitzer & nicht für eine Begleitung.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          7.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Dem Besucher ist klar, dass jede Location ihren eigenen “Dresscode” hat, welcher zum Programm angepasst ist. Dem Besucher kann ebenfalls der Eintritt verwehrt werden, wenn dieser dem Anschein macht, so alkoholisiert zu sein, dass eine Gefahr für die anderen Besucher besteht.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          8.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Besucher, die mutwillig Einrichtungs-, Dekorationsgegenstände oder Veranstaltungstechnik zerstören oder beschädigen, haften für den entstandenen Schaden und werden ggf. mit Hausverbot oder Verweis der Veranstaltung belegt.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          9.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Personen, die versuchen, den reibungslosen Ablauf der Veranstaltung zu stören, andere Gäste oder Personal zu belästigen, werden von der Veranstaltung verwiesen.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          10.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Den Anweisungen des Sicherheits- sowie Veranstaltungspersonals ist Folge zu leisten.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          11.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Das Mitbringen von Speisen & Getränken ist nicht gestattet.</p>
      </li>

      <li>
        <h3 class="text-2xl pt-4 relative group">
          12.
          <span class="absolute inset-x-0 bottom-1 bg-purple-600/60 h-2 w-6 rounded-lg z-[-1]" style="background-color: #ca0001;opacity:60%;width: 100%;border-radius:4px;position: absolute;bottom: 4px;height: 8px;left:0;z-index: -1"></span>
        </h3>
        <p class="ml-5">Das Mitführen von Waffen, waffenähnlichen Gegenständen & Substanzen, die gegen das Betäubungsmittelgesetz verstoßen, ist nicht gestattet. Das Sicherheitspersonal wird diese konfiszieren und eine Strafanzeige stellen.</p>
      </li>
    </ol>
  </div>
</template>

<script>
import {userStore} from "@/store/userStore"

export default {
  name: "agb",
  title:'AGB',
  components: {},
  setup() {
    const user = userStore()
    return {user}
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped>
h2{
  font-size: 36px;
  font-weight: bolder;
}
h3{
  font-size: 24px;
}
h4{
  font-size: 20px;
}
.font-medium{
  font-weight: 500;
}
.pt-4 {
  padding-top: 16px;
}
.pl-5{
  padding-left: 20px;
}
.list-disc{
  list-style: disc;
}
.list-decimal{
  list-style: decimal;
}
a{
  text-decoration-color: #ca0001;
  color:#ca0001;
  font-weight: 600;
}
.w-fit{
  width: fit-content;
}
.relative{
  position: relative;
  width: fit-content;
}
</style>
