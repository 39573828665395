<template>
  <div>
    <main class="content-500">
      <div class="t-center pad-16">
        <h3 style="font-size: 2em;" class="f-bold color-white">🚕<br>Taxiruf</h3>
        <h4 style="font-size: 1.3em;" class="color-blue primaryColor">
          👉 Schnell und einfach ein Taxi rufen
        </h4>
        <p class="t-center" style="margin-top: 8px;">
          Wähle das Event aus, auf dem du dich befindest und erhalte passende
          Taxi-Unternehmen, welche dich sicher nach hause fahren.
        </p>
      </div>
      <div class="pad-16">
        <div class="field simple">
          <label style="padding-bottom: 6px;">Event auswählen</label>
          <select v-model="eventId" @change="getTaxi()" style="margin-bottom: 16px;">
            <option value="-1" disabled>
              Event...
            </option>
            <option v-for="event in events" :key="event.id" :value="event.id">
              {{ event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] + ' - ' + event.title }}
            </option>
          </select>
        </div>
        <section>
          <div v-for="taxi in taxen" :key="taxi.id" class="pad-16 br-10 bgcolor-blue" style="margin-top: 8px;">
            <div>
              <h3 class="color-white f-bold" style="font-size: 1.2em;">{{ taxi.name }}</h3>
              <h5 style="margin-top: 8px; margin-bottom: 16px; color: #ffffff66">{{ taxi.phone }}</h5>
            </div>
            <a class="button t-center" style="display: block;" :href="'tel:'+taxi.phone">Taxi Anrufen</a>
          </div>
          <p v-if="eventId !== -1 && taxen.length === 0" class="t-center color-white">
            Für dieses Event wurden keine Taxi-Unternehmen hinterlegt.
          </p>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "taxiruf",
  title: "Taxiruf",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      eventId: -1,
      events: [],
      taxen: []
    }
  },
  mounted() {
    this.loadEvents()
  },
  methods:{
    loadEvents(){
      const _this = this
      this.axios.post('home/getEvents')
          .then((response) => {
            if(response.status === 250){
              _this.events = response.data;
            }
          })
    },
    getTaxi(){
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.eventId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('taxiruf/getTaxi', fD)
          .then((response) => {
            if(response.status === 250){
              _this.taxen = response.data
            }
          })
    },
  }
}
</script>

<style scoped>
  label{
    display: block;
    font-weight: normal;
    margin-bottom: 4px;
  }
  .field select{
    padding: 12px;
    border: none;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.1);
    box-sizing: border-box;
    color: #ffffff;
  }
</style>