<template>
  <div class="grid-12">
    <div class="uploadelement col-sp-6 row-sp-12 pad-4" v-for="(singleMedia, index) in media" :key="singleMedia.id">
      <div class="uploadelement-box">
        <div class="br-10 bgcolor-black">
          <div class="" style="border-radius: 8px;">
            <div class="imageBox" @click="popup = true; slideOpenAt= index ">
              <img :src="config.projectURL + config.eventMediaUploadPath + singleMedia.eventId + '/' + singleMedia.name + '_thumb.jpg'" v-if="['jpg', 'jpeg', 'gif', 'png', 'webp'].includes(singleMedia.extension)">
              <video :src="config.projectURL + config.eventMediaUploadPath + singleMedia.eventId + '/' + singleMedia.name + '.' + singleMedia.extension" style="height: 100%; width: 100%" muted playsinline v-else-if="['mp4', 'webm'].includes(singleMedia.extension)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="vueModal" v-if="popup">
    <div class="vueModalOuter" @click="popup =false" />
    <div class="vueModalInner" style="height: 90vh;max-height: 750px; width: 90vw;max-width: 450px;box-sizing: border-box">
      <div class="t-center pad-8" style=" height: 80%">
        <Swiper
            ref="mySwiperRef"
            :navigation="true"
            :initial-slide="slideOpenAt"
            :modules="modules"
            class="mySwiper"
            style="width: 100%;height: 100%"
        >
          <swiper-slide v-for="singleMedia in media" :key="singleMedia.name" style="width: 100%;max-height: 80vh;display: flex;align-items: center">
            <img :src="config.projectURL + config.eventMediaUploadPath + singleMedia.eventId + '/' + singleMedia.name + '.' + singleMedia.extension" style="height: auto; width: 100%;border-radius: 8px" v-if="['jpg', 'jpeg', 'gif', 'png', 'webp'].includes(singleMedia.extension)">
            <video :src="config.projectURL + config.eventMediaUploadPath + singleMedia.eventId + '/' + singleMedia.name + '.' + singleMedia.extension" style="height: auto; width: 100%;border-radius: 8px" controls playsinline v-else-if="['mp4', 'webm'].includes(singleMedia.extension)" />
          </swiper-slide>
        </Swiper>
      </div>
      <div class="pad-16 t-center">
        <button class="button bgcolor-back color-white" @click="popup = false" >
          Schließen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper,SwiperSlide,useSwiper } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
  name: "media",
  props: {
    media: Array,
    eventId: Number,
    eventTitle: String
  },
  components:{
    Swiper,
    SwiperSlide,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    const swiper = useSwiper();

    return {onSwiper,swiper,
      onSlideChange, auth, config, user,modules:[Navigation,Pagination,Scrollbar,A11y]}
  },
  data() {
    return {
      popup: false,
      slideOpenAt:null
    }
  },
  methods: {
    download(){
      const link = document.createElement('a')
      link.href = this.config.projectURL + this.config.eventMediaUploadPath + this.eventId + '/' + this.media.name + '.' + this.media.extension
      link.download = this.eventTitle + '_' + this.media.name + '.' + this.media.extension
      link.click();
    },
    consi(a){
      console.log(a)
    }
  }
}
</script>

<style scoped>
.swiper-wrapper{
  align-items: center;
}
.vueModal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px) brightness(0.5);
}
.vueModalOuter{
  position: absolute;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: 0;
}
.vueModalInner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  border-radius: 10px;
  background-color: #121212;
  max-height: 90vh;
  max-width: 90vh;
  overflow: auto;
}
.button{
  text-align: center;
  margin: 4px;
}
.uploadelement{
  position: relative;
}
.swiper-wrapper{
  align-items: center;
}
.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 8px;
  font-size: 1.2em;
  color: rgba(0,0,0,0.6);
  height: 100%;
}
.loader .loader-animation{
  width: calc(100% - 64px);
  height: 32px;
  position: absolute;
  top: 100px;
  left: 32px;
  background-color: rgba(0,0,0,0.05);
  border: 4px solid #135969;
  border-radius: 4px;
}
.loader .loader-animation .topleft{
  position: absolute;
  background-color: #135969;
  width: 16px;
  height: 4px;
  top: -4px;
  left: -16px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.loader .loader-animation .toptop{
  position: absolute;
  background-color: #135969;
  width: 4px;
  height: 12px;
  top: -20px;
  left: -4px;
  border-radius: 4px;
}
.loader .loader-animation .bottomright{
  position: absolute;
  background-color: #135969;
  width: 16px;
  height: 4px;
  bottom: -4px;
  right: -16px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.loader .loader-animation .bottombottom{
  position: absolute;
  background-color: #135969;
  width: 4px;
  height: 16px;
  bottom: -16px;
  right: -4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.loader .loader-animation .process{
  position: absolute;
  color: #ffffff;
  background-color: #ff8565;
  top: 0;
  left: 0;
  height: 100%;
  text-align: center;
  line-height: 32px;
}
.loader h6{
  position: absolute;
  width: 100%;
  top: 160px;
}
.imageBox{
  overflow: hidden;
  width: 100%;
  height: 180px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  background-color: var(--main-bg-color);
}
.imageBox img{
  min-width: 250px;
  max-width: 400px;
  object-fit: contain;
  height: auto;

}

</style>