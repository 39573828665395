<template>
  <section class="content-500" style="margin-top: 72px;padding: 12px;position: relative">

    <div style="padding: 8px;column-gap: 12px;row-gap: 12px" class="grid-12" >
      <div class="col-sp-12 row-sp-12">
        <img style="width: 100%;height: auto" :src="`${config.projectURL}assets/images/media/${album.cover}_thumbnail.jpg`">
        <div style="padding: 8px; background-color: #00000030;">
          <h3 style="text-align: center;font-size: 18px">{{album.title}}</h3>
          <p style="white-space: pre-line;padding: 12px; font-size: 0.9em;">{{album.description}}</p>
        </div>
      </div>

        <div  v-for="(media,index) in medias" :key="media.id" class="col-sp-6 row-sp-12" @click="slideOpenAt=index;popup=true">
          <div style="width: 100%;height: 100%;display: flex;justify-content: center;overflow: hidden;border-radius: 8px">
            <img style="height:100%;width: auto;object-fit: contain" :src="`${config.projectURL}assets/images/media/${media.file}_thumbnail.jpg`">
          </div>
        </div>
      <div ref="scrollEndMarker" style="height: 1px;"></div>
    </div>
    <div class="vueModal" v-if="popup" style="position: fixed;top: 0px;left: 0px;width: 100%">
      <div class="vueModalOuter" @click="popup =false" />
      <div class="vueModalInner" style="height: 90vh;max-height: 750px; width: 90vw;max-width: 450px;box-sizing: border-box">
        <div class="t-center pad-8" style=" height: 80%">
          <Swiper
              ref="mySwiperRef"
              :navigation="true"
              :initial-slide="slideOpenAt"
              :modules="modules"
              class="mySwiper"
              style="width: 100%;height: 100%"
              @reachEnd="doSomething"
          >
            <swiper-slide v-for="singleMedia in medias" :key="singleMedia.name" style="width: 100%;max-height: 80vh;display: flex;flex-direction: column;justify-content: center">
              <img :src="`${config.projectURL}assets/images/media/${singleMedia.file}_small.jpg`" style="height: auto; width: 100%;border-radius: 8px" v-if="['jpg', 'jpeg', 'gif', 'png', 'webp'].includes(singleMedia.extension)">
              <video :src="config.projectURL + config.eventMediaUploadPath + singleMedia.eventId + '/' + singleMedia.name + '.' + singleMedia.extension" style="height: auto; width: 100%;border-radius: 8px" controls playsinline v-else-if="['mp4', 'webm'].includes(singleMedia.extension)" />
              <div style="margin: 12px 0;height: fit-content">
                <div :style="singleMedia.error?'justify-content:center;':'justify-content: space-between;'" style="display: flex;margin: 12px auto 0">
                  <button v-if="!singleMedia.error" class="button meldeButton" @click="singleMedia.report=true">Melden</button>
                  <a class="button" :href="`${config.projectURL}assets/images/media/${singleMedia.file}.jpg`" :download="album.title+' - Foto'" style="background-color: #d2420d;border-radius: 8px;color: white;width: fit-content">
                    Download
                  </a>
                </div>
                <p v-if="singleMedia.error" style="margin:12px 0">{{singleMedia.error}}</p>

              </div>
              <div style="margin: 8px" v-if="singleMedia.report && !singleMedia.error">
                <textarea v-model="reportText" placeholder="Begründe warum du das Bild melden Möchtest" style="padding:6px;width: 90%;background-color: #4c4f52;color:white" rows="4">
                </textarea>
                <div style="width: 100%;display: flex;justify-content: space-between;padding: 12px 0">
                  <button class="button" @click="reportText='';singleMedia.report=false">Abbrechen</button>
                  <button class="button bgcolor-red" @click="reportPicture(singleMedia)">Abschicken</button>
                </div>
              </div>
            </swiper-slide>
          </Swiper>
        </div>
        <div class="pad-16 t-center">
          <button class="button bgcolor-back color-white" @click="popup = false" >
            Schließen
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";
import {Swiper, SwiperSlide, useSwiper} from "swiper/vue";
import {A11y, Navigation, Pagination, Scrollbar} from "swiper";

export default {
  name: "album",
  components: {Swiper,
    SwiperSlide,},
  title:'Album',
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    const swiper = useSwiper();

    return {onSwiper,swiper,
      onSlideChange, auth, config, user,modules:[Navigation,Pagination,Scrollbar,A11y]}
  },
  data() {
    return {
      album:{},
      medias:[],
      albumId: this.$route.params.id,
      popup: false,
      slideOpenAt:null,
      reportText:'',
      skipValue:0,
      loadingImages:false,
    }
  },
  mounted() {
    console.log(this.albumId)
    this.config.loading = false
    this.loadPictures()
    const observer = new IntersectionObserver(entries => {
      // Überprüfe, ob das Element im sichtbaren Bereich liegt
      if (entries[0].isIntersecting) {
        // Funktion aufrufen, wenn das Element sichtbar wird
        this.doSomething();
      }
    });
    // Ziel-Element für den Observer festlegen (in diesem Fall der unterste Bereich des Scrollcontainers)
    observer.observe(this.$refs.scrollEndMarker);
  },
  methods: {
    loadPictures(){
      this.loadingImages=true
      aSyncData('media/getById',{albumId:this.albumId, skip:(this.skipValue * 10) })
          .then(r => {
            if(r.status === 250){
              this.album = r.data.album
              this.medias = [...this.medias, ...r.data.media]
              console.log(this.medias)
            }
            this.loadingImages=false
          })
    },
    reportPicture(singleMedia){
      if (this.reportText.length <1)return
      aSyncData('media/report',{id:singleMedia.id,reason:this.reportText})
          .then(r => {
            if(r.status === 250){
              singleMedia.error='Bild gemeldet'
            }
            if(r.status === 251){
              singleMedia.error="Deine Meldung ging leider nicht durch, da du je Monat nur 2 Bilder melden darf. Melde dich gerne direkt über unserem Kontaktformular oder Email."
            }
          }).finally(this.reportText='',singleMedia.report =false)
    },

    doSomething() {
      console.log('bilder')
      if (this.loadingImages)return
      this.skipValue++
      this.loadPictures()
      console.log("Neue Bilder laden!");
    }
  }
}
</script>

<style scoped>
.swiper-wrapper{
  align-items: center;
}
.vueModal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px) brightness(0.5);
}
.vueModalOuter{
  position: absolute;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: 0;
}
.vueModalInner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  border-radius: 10px;
  background-color: #121212;
  max-height: 90vh;
  max-width: 90vh;
  overflow: auto;
}

</style>