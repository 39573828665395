<template>
  <article class="ticket">
    <h4 class="color-white">
      {{ ticketItems.ticketTitle }}
      <span v-if="ticketItems.available < 1" class="button bgcolor-red color-white tiny" style="padding: 4px 8px; font-size: 0.5em;">Ausverkauft</span>
      <span v-else-if="ticketItems.available <= 20" class="button bgcolor-red color-white tiny" style="padding: 4px 8px; font-size: 0.5em;">Fast ausverkauft</span>
      <span v-if="ticketItems.badge" class="button bgcolor-blue color-white tiny" style="padding: 4px 8px; font-size: 0.5em; margin-left: 8px;">{{ticketItems.badge}}</span>
    </h4>
    <p class="ticketDescription" v-html="nl2br(ticketItems.ticketDescription )" />
    <div class="grid-12">
      <div class="col-sp-6 row-sp-12">
        <h5>{{ ticketItems.price }}€ <span>(inkl. MwSt.)</span></h5>
      </div>
      <div class="col-sp-6 row-sp-12 t-right" v-if="ticketItems.available > 0">
        <button class="bgcolor-white bgprimaryBtnColor button color-black" :disabled="loading" :class="{disabled: loading}" @click="checkOutModal = true; triggerPixel();">
          Ticket kaufen
        </button>
      </div>
    </div>
  </article>
  <VueModal :show="checkOutModal" @close="checkOutModal = false" min-size="small" max-size="large">
    <check-out :item="ticketItems" checkout-type="tickets" @close="checkOutModal = false" />
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import CheckOut from "@/components/payment/checkOut";
import {nl2br} from "@/modules/main";

export default {
  name: "TicketItems",
  components: {CheckOut},
  props: ["ticketItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      ticketCount: 0,
      loading: false,
      checkOutModal: false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    nl2br(str){
      return nl2br(str)
    },
    triggerPixel(){
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

        ttq.load('CGCR9LRC77U734TI19L0');
        ttq.page();
      }(window, document, 'ttq');
    }
  }
}
</script>

<style scoped>
.ticket{
  margin-top: 8px;
  cursor: pointer;
  border-bottom: 2px solid rgba(255,255,255,0.05);
  border-radius: 10px;
  padding: 16px;
  transition: 0.3s;
}
.ticket h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  font-weight: 800!important;
  margin-top: 4px;
}
.ticket p.ticketDescription{
  color: rgba(255,255,255,0.8)!important;
  font-weight: 200;
  font-size: 1.1em;
  margin-bottom: 16px;
}
.ticket h5{
  font-size: 1.4em;
  font-family: 'Helvetica';
  font-weight: 800;
  margin-bottom: 24px;
  margin-top: 16px;
}
.ticket h5 span{
  font-size: 0.7em;
}
.ticket button{
  margin-top: 16px;
}
</style>