<template>
  <span class="label">
    {{ eventLabels.emoji }} {{ eventLabels.title }}
  </span>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "EventLabels",
  props: ["eventLabels"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style>
span.label{
  padding: 8px 10px;
  border-radius: 16px;
  font-weight: 400;
  font-size: .9em;
  font-family: 'Helvetica';
  margin-right: 8px;
  background-color: var(--second-bg-color)!important;
  color: #ffffff;
}
</style>